import { ScreenLoading } from "@components";
import { callbackSurveyService } from "@services";
import { HTTP_CODE_OK } from "@constants";

export default {
    name: "CallbackSurvey",
    components: {
        ScreenLoading,
    },
    data() {
        return {};
    },
    methods: {
        callbackSurveyInitial: async function () {
            this.isFetchingPage = true;
            await callbackSurveyService.questionnaires({
                key: this.$route.query.key,
            }).then((res) => {
                const data = res.data;
                if (data && res.status == HTTP_CODE_OK) {
                    this.$router.push({
                        name: "lottery.game",
                        params: {
                            campaignUrlName: data.campaign_url_name,
                            lotteryUuid: data.lottery_uuid,
                        },
                    });
                } else {
                    this.$router.push({
                        name: "campaign.error",
                        replace: true,
                    });
                }
            });
            this.isFetchingPage = false;
        },
    },
    created() {
        this.callbackSurveyInitial();
    },
};
